import { useState, useRef, useEffect } from "react";
import img1 from "../../assets/coaches/c1.jpg";
import img2 from "../../assets/coaches/c2.jpg";
import img3 from "../../assets/coaches/c3.jpg";
import img4 from "../../assets/coaches/c4.jpg";
import img5 from "../../assets/coaches/c5.jpg";
import img6 from "../../assets/coaches/c6.jpg";
import img7 from "../../assets/coaches/c7.jpg";
import img8 from "../../assets/coaches/c8.jpg";
import img9 from "../../assets/coaches/c9.jpg";
import img10 from "../../assets/coaches/c10.jpg";
import img11 from "../../assets/coaches/c11.jpg";
import img12 from "../../assets/coaches/c12.jpg";
import img13 from "../../assets/coaches/c13.jpg";
import img14 from "../../assets/coaches/c14.jpg";
import img15 from "../../assets/coaches/c15.jpg";
import img16 from "../../assets/coaches/c16.jpg";
import img17 from "../../assets/coaches/c17.jpg";
import img18 from "../../assets/coaches/c18.jpg";
import img19 from "../../assets/coaches/c19.jpg";
import img20 from "../../assets/coaches/c20.jpg";
import img21 from "../../assets/coaches/c21.jpg";
import img22 from "../../assets/coaches/c22.jpg";
import img23 from "../../assets/coaches/c23.jpg";
import img24 from "../../assets/coaches/c24.jpg";
import img25 from "../../assets/coaches/c25.jpg";
import img26 from "../../assets/coaches/c26.jpg";
import img27 from "../../assets/coaches/c27.jpg";
import img28 from "../../assets/coaches/c18.jpg";



import "./background.css";
const Background = () => {
  const images1 = [img1, img8, img15, img22];
  const images2 = [img9, img2, img23, img16];
  const images3 = [img3, img10, img17, img24];
  const images4 = [img4, img11, img18, img25];
  const images5 = [img5, img12, img19, img26];
  const images6 = [img6, img13, img20, img27];
  const images7 = [img7, img14, img21, img28];
  
  const [currentSlide, setCurrentSlide] = useState(0);
  // useRef does not cause a re-render
  let sliderInterval = useRef();
  let switchImages = () => {
    if (currentSlide < images1.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };
  useEffect(() => {
    sliderInterval = setInterval(() => {
      switchImages();
    }, 5000);
    return () => {
      clearInterval(sliderInterval);
    };
  });
  return (
    <div className="mainanim" id="mainanim">
    <div className="anim1">

    <div className="imgWrapper">
      {images1.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

    </div>
    <div className="anim2">
    <div className="imgWrapper">
      {images2.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>
 <div className="anim3">
 <div className="imgWrapper">
      {images3.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>
<div className="anim4">
 <div className="imgWrapper">
      {images4.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim5">
 <div className="imgWrapper">
      {images5.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim6">
 <div className="imgWrapper">
      {images6.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

<div className="anim7">
 <div className="imgWrapper">
      {images7.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>

</div>

    
    </div>
  );
};

export default Background;