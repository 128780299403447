import React from "react";
import './footer.css';
import Github from '../../assets/github.png';
import Instagram from '../../assets/instagram.png';
import Linkedin from '../../assets/linkedin.png';
import Logo from '../../assets/logo.png';

const Footer=()=>{
    return(
<div className="footer-container">
     <hr/>
     <div className="footer">
     <div className="social-links">
     <img src={Github} alt="" />
        <img src={Instagram} alt="" />
        <img src={Linkedin} alt="" />
        
     </div>
     <div className="contact">
     <span>TEL: 0715990317(Chamath)    E MAIL:  madamith1994@gmail.com    ADDRESS: 12/5,Main street,Kalawana</span>
     </div>
     <div className="logo-f">
        <img src={Logo} alt="" />
     </div>
     </div>
       <div className="blur blur-f-1"></div>
       <div className="blur blur-f-2"></div>
</div>
    )
}
export default Footer;