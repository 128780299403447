import React from 'react';
import Header from './Header/Header';
import Heart from '../assets/heart.png';
import Hero_image1 from '../assets/hero_image1.png';
import Hero_image_back from '../assets/hero_image_back.png';
import Calories from '../assets/calories.png'
import './hero.css';
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter';

const heros=()=>{
    const transition={type:'spring',duration:3}
    const mobile=window.innerWidth<=768 ? true:false;
    return(
        <div className="hero" id="home">
        <div className="blur hero-blur"></div>
        <div className="left-h">
        <Header/>
        <div className="the-best-ad">
            <motion.div
                initial={{left:mobile? '178px':'230px'}}
                whileInView={{left:'8px'}}
                transition={{...transition,type:'tween'}}>
            </motion.div>
            <span>the best fitness club in the Kalawana</span>
        </div>
        <div className='hero-text'>
            <div>
                <span className='stroke-text'>Shape </span>
                <span>Your</span>
            </div>
            <div>
                <span>
                    Ideal Body
                </span>
            </div>
            <div>
            <span>
             in were we will help you to shape and build your adeal Body and live up your life to fullest
            </span>
            </div>
        </div>

        <div className='figures'>
        <div>
            <span>
                <NumberCounter end={140} start={100} delay='4' preFix="+"/>
            </span>
            <span>expert coaches</span>
        </div>
        <div>
        <span>
        <NumberCounter end={978} start={800} delay='4' preFix="+"/>
        </span>
        <span>member joined</span>
        </div>
        <div>
        <span>
        <NumberCounter end={50} start={0} delay='4' preFix="+"/>
        </span>
        <span>fitness Programs</span>
        </div>
        </div>

        <div className='hero-buttons'>
        <button className='btn'>Get Started</button>
        <button className='btn'>Learn More</button>
        </div>

        </div>
       <div className="right-h">
        <button className='btn'>Join Now</button>

        <motion.div className='heart-rate'
        initial={{right:'1rem'}}
        whileInView={{right:'4rem'}}
        transition={transition}>
        <img src={Heart} alt=''/>
        <span>Heart Rate</span>
        <span>166 bpm</span>
        </motion.div>

        <img  src={Hero_image1} alt="" className='hero-image'/>
        <motion.img
        initial={{right:'11rem'}}
        whileInView={{right:'20rem'}}
        transition={transition}
         src={Hero_image_back} alt="" className='hero-image-back' />
       
        <motion.div
        initial={{left:'1rem'}}
        whileInView={{left:'4rem'}}
        transition={transition}
         className='calories'
         >
        <img src={Calories} alt=""/>
        <div>
        <span>Calories Burned</span>
        <span>220 Kcal</span>
        </div>
        </motion.div>
          

       </div>
        </div>
    )
}
export default heros;